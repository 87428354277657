<template>
  <div class="select-template-dialog">
    <el-dialog
      width="800"
      :title="$t('layout.Link Template')"
      :visible.sync="showTemplateDialg"
      :before-close="handleTemplateClose"
      :close-on-click-modal="false"
      table-layout="fixed"
      >
        <div class="template-type" v-if="showRadio">
          <el-radio-group v-model="sizeType"  @change="getList">
            <el-radio label="same">{{$t('layout.Same Size')}}</el-radio>
            <el-radio label="other">{{$t('layout.Other')}}</el-radio>
          </el-radio-group>
          <div class="ceate">
            <el-button type="primary" size="small" @click="createTemplate">
              <i class="el-icon-plus" />
              {{ $t("button.New") }}
            </el-button>
          </div>
        </div>
        <el-table
          ref="multipleTable"
          style="width:100%, margin-bottom: 20px;"
          :data="tableList"
          height="400"
          row-key="coorId"
          @selection-change="handleSelectionChange"
          @current-change="handleRadioCheck"
          @sort-change="handleSort"
          >

            <el-table-column type="index" width="50">
              <el-table-column  width="50" align="center">
                <template slot-scope="scope">
                  <div style="display: flex; align-items: center;">
                    <el-radio-group v-model="templateInfo" size="small">
                      <el-radio :label="scope.row" size="large"><span style="display: none;">1</span></el-radio>
                    </el-radio-group>
                  </div>
                </template>
              </el-table-column>
            </el-table-column>

            <el-table-column
              :label="$t('layout.Store Name')"
              prop="storeName"
              header-align="center"
              min-width="205"
              >
              <el-table-column prop="storeName" min-width="205" align="center">
                <template slot="header" slot-scope="scope">
                  <el-input style="width: 140px" v-model="tableInfo.storeName" @change="getList" size="small" clearable></el-input>
                </template>
              </el-table-column>
            </el-table-column>

            <el-table-column
              :label="$t('layout.Template Name')"
              sortable="custom"
              prop="template_name"
              header-align="center"
              >
              <el-table-column prop="templateName" width="205" align="center">
                <template slot="header" slot-scope="scope">
                  <el-input style="width: 140px" v-model="tableInfo.templateName" @input="getList" clearable size="small"></el-input>
                </template>
              </el-table-column>
            </el-table-column>

            <el-table-column
              :label="$t('layout.Template Number')"
              sortable="custom"
              prop="template_code"
              header-align="center"
              >
              <el-table-column prop="templateCode" width="205" align="center">
                <template slot="header" slot-scope="scope">
                  <el-input style="width: 140px" v-model="tableInfo.templateCode" @input="getList" size="small" clearable></el-input>
                </template>
              </el-table-column>
            </el-table-column>

            <el-table-column
              :label="$t('layout.Template Type')"
              sortable="custom"
              prop="template_type_id"
              header-align="center"
              >
              <el-table-column prop="templateTypeName" width="205" align="center">
                <template slot="header" slot-scope="scope" align="center">
                  <!-- <el-input style="width: 140px" v-model="tableInfo.templateType" @input="getList" size="small"></el-input> -->
                  <el-select v-model="tableInfo.templateTypeId" placeholder="" size="small"  @change="getList" clearable :disabled="templateTypeDisabled">
                    <el-option
                      v-for="item in templateTypeList"
                      :key="item.templateTypeId"
                      :label="item.templateTypeName"
                      :value="item.templateTypeId"
                    />
                  </el-select>
                </template>
              </el-table-column>
            </el-table-column>

            <el-table-column
              :label="$t('layout.Template Photo')"
              sortable="custom"
              prop="template_image_url"
              header-align="center"
              >
              <el-table-column prop="templatePhoto" width="205">
                <template slot="default" slot-scope="scope">
                  <div class="template-img">
                    <img class="img" :src="scope.row.templateImageUrl"></img>
                  </div>
                </template>
              </el-table-column>
            </el-table-column>
        </el-table>
        <pagination :tableInfo="tableInfo" @changePage="changePage" />
        <span slot="footer" class="dialog-footer">
          <el-button size="small" class="cancel-btn" @click="showTemplateDialg = false">{{$t('button.Cancel')}}</el-button>
          <el-button size="small" class="save-btn" type="success" @click="save()">{{$t('button.Confirm')}}</el-button>
        </span>

    </el-dialog>
  </div>
</template>
<script>
import { getTemplateList, getTemplateTypeList} from '@/libs/api/scenario-item'
import { Handler } from 'leaflet'
import { debounce } from 'lodash';
import pagination from '@/components/pagination'

export default {
  data() {
    return {
      showTemplateDialg:false,
      tableList: [],
      templateTypeList:[],
      templateInfo: {
        templateId: '',
        templateName: '',
        templateTypeId:''
      },
      tableInfo: {
        storeName: '',
        templateName:'',
        templateCode: '',
        templateType: '',
        storeName: '',
        orders:[],
        current: 1,
        size: 10,
        total: 0
      },
      sizeType:'same',
    }
  },
  components:{
    pagination
  },
  props: {
    showRadio: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
    storeType:{
      type: String,
      default: '',
    },
    templateTypeDisabled:{
      // Template Type 不可选，且带入已有的 templateTypeId参数
      type: Boolean,
      default: false,
    },
    templateTypeId:{
      type: String,
      default: '',
    },
    scenarioId:{
      // 场景id，用于跳转新增模板时，把该参数带入到新建模板中
      type: String,
      default: '',
    }
  },
  watch: {
    templateTypeId: function(val) {
      console.log('--watch--templateTypeId',val)
      this.tableInfo.templateTypeId = val
    }
  },
  mounted() {
  },
  methods: {
    // 打开弹窗
    show(){
      this.showTemplateDialg =true
      this.initData()
    },
    // 关闭弹窗
    handleTemplateClose() {
      this.showTemplateDialg = false
      this.templateInfo = {}
    },
    // 初始化--获取数据
    initData() {
      this.getList()
      this.getTemplateTypeList()
    },
    // 页码发生变化时的回调函数
    changePage() {
      this.getList()
    },
    // 处理行点击时候，进行单选
    handleRadioCheck(val){
      if (val) this.templateInfo = val
    },
    handleSelectionChange() {

    },
    // 获取数据列表
    getList:debounce (async function() {
      console.log('获取模板列表-----')
      // todo: 字段确认
      const params = {
        storeName: this.tableInfo.storeName,
        templateName: this.tableInfo.templateName,
        templateCode: this.tableInfo.templateCode,
        templateType: this.tableInfo.templateType,
        templateTypeId: this.tableInfo.templateTypeId,
        current: this.tableInfo.current,
        size: this.tableInfo.size,
        orders: this.tableInfo.orders,
        scenarioId:this.scenarioId
      }
      if(this.showRadio){
        params.storeType = localStorage.getItem('layoutStoreType') || this.storeType
        params.storeId = localStorage.getItem('storeId')
        if(this.sizeType === 'same'){
          params.templateType= this.width+'*'+this.height
          params.width =''
          params.height=''
          
        } else {
          params.templateType= ''
          params.width =this.width
          params.height=this.height
        }
        
      }
      try {
        const res = await getTemplateList(params)
        if (res.status === 200 && res?.data?.code === 1) {
          this.tableList = res.data?.data?.records
          this.tableInfo.total = res.data?.data?.total
          console.log('this.tableList=====', this.tableList)
        } else {
          this.$message({
            message: res?.data?.message,
            type: 'warning'
          });
        }
      } catch (error) {
        this.$message.error(error.message)
      }
      
    },100),
    // 获取模板类型
    async getTemplateTypeList(){
      try {
        const res = await getTemplateTypeList({})
        let response = res.data
        if(response.state){
          this.templateTypeList = response.data
        } else{
          this.$message({
            message: response.message,
            type:'warning',
          });
        }
      } catch (err) {
        const { message} = err;
        this.$message.error(message)
      }
    },
    // 排序
    handleSort(column){
      console.log('排序',column)
      let arr = []
      if(column.order){
        let str1= column.order === "ascending" ? "asc" : "desc";
        str1 = str1 + ':'+ column.prop;
        arr.push(str1)
      } else {
        arr = []
      }
      
      this.tableInfo.orders = arr;
      this.getData()
    },
    // 点击保存
    save() {
      console.log('templateId', this.templateInfo)
      this.showTemplateDialg = false
      this.$emit('onTemplateConfirm',this.templateInfo)
      this.templateInfo = {}
    }, 
    // 新增模板按钮点击
    createTemplate(){
      this.$router.push({
        name: 'template-list',
        params: {
          width:this.width,
          height:this.height,
          scenarioId:this.scenarioId
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.select-template-dialog{
  .template-img{
    width: 40px;
    height: 40px;
    margin: 0 auto;
    .img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .template-type{
    text-align: center;
    margin-top: -20px;
    margin-bottom: 10px;
    position: relative;
    ::v-deep .el-radio__label{
      margin-left: 10px;
      margin-bottom: 0;
    }
    .ceate{
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  // 重置样式
  ::v-deep .el-table--border .el-table__cell{
    border-right:1px solid #EBEEF5 !important;
  }
  ::v-deep .el-table--border .el-table__cell:first-child .cell{
    padding: 0;
  }
  ::v-deep .el-radio{
    padding: 10px 18px;
    margin-bottom: 0;
  }
  ::v-deep .el-radio__label{
    padding: 0;
  }
  ::v-deep .el-dialog{
    width: 1200px;
    margin-top: 5vh!important;
    .el-dialog__body{
      padding: 30px !important;
    }
  }
  .dialog-footer ::v-deep {
    text-align: center;
    .el-button.cancel-btn {
      background: #d4d4d4 !important;
      box-shadow: 0px 1px 15px 0px rgba(99, 99, 99, 0.23);
      color: #626262;
    }
  }
  ::v-deep  .el-button{
    width: 120px;
  }
  ::v-deep .el-table__row{
    cursor: pointer;
  }

}

</style>